var pawLibrary = {
  map: false,
  fbAccessToken: "1046599778716357|Ru5dCXFhXsqfy2-ziNbyZEGmOhs",
  monthNames: [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "June",
    "Jul",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ],
  fullMonthNames: [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ],
  loading:
    '<div id="loader" class="center" style="width:100%"><img src="./imgs/loader.gif" alt="Loading..."  /></div>',
  markers: [],
  offset: 0.8,
  setPayments: function (clientToken, threedsecure, billing) {
    $("#loader").hide();
    var submitButton = document.querySelector("#checkbox_btn");
    var amount = $("#amount").val();
    var checkout_frm = $("#checkout-frm");
    var processOrder = $("#processing-order");

    // ENABLE 3DSECURE
    if (threedsecure != 1) {
      console.log("3d sexure check running");
      braintree.dropin.create(
        {
          authorization: clientToken,
          container: "#payment-form",
          threeDSecure: {
            amount: amount,
            version: 2,
            email: billing.customer_email,
            billingAddress: {
              givenName: billing.givenName,
              surname: billing.surname,
              phoneNumber: billing.bill_phone.replace(/[\(\)\s\-]/g, ""), // remove (), spaces, and - from phone number,
              streetAddress: billing.bill_add1,
              extendedAddress: billing.bill_add2,
              postalCode: billing.bill_pcode,
              countryCodeAlpha2: billing.alphaCode,
            },
          },
          paypal: {
            flow: "checkout",
            amount: amount,
            currency: "GBP",
          },
        },
        function (createErr, instance) {
          submitButton.addEventListener("click", function (e) {
            e.preventDefault();
            instance.requestPaymentMethod(function (err, payload) {
              console.log("ERROR ", err);
              console.log("PAYLOAD ", payload);

              if ($("#validation-msg").length > 0) {
                $("#validation-msg").remove();
              }

              $("#loader").show();

              if (err) {
                console.log("tokenization error:");
                console.log(err);
                console.log(payload);

                if (err.details.originalError) {
                  if (err.details.originalError.error.message) {
                    var error_txt = err.details.originalError.error.message;
                    checkout_frm.insertBefore(
                      '<div id="validation-msg">' + error_txt + "</div>"
                    );
                  }
                } else {
                  checkout_frm.insertBefore(
                    '<div id="validation-msg">Paypal not available at this time.</div>'
                  );
                }

                instance.clearSelectedPaymentMethod();
                return;
              }

              if (payload.type !== "CreditCard") {
                submitButton.removeEventListener(
                  "click",
                  arguments.callee,
                  false
                );
                checkout_frm.hide();
                checkout_frm.append(
                  '<input name="payment_method_nonce" type="hidden" value="' +
                    payload.nonce +
                    '" />'
                );
                processOrder.removeClass("hidden");
                checkout_frm.submit();
              } else {
                if (payload.liabilityShifted == true) {
                  submitButton.removeEventListener(
                    "click",
                    arguments.callee,
                    false
                  );
                  checkout_frm.hide();
                  checkout_frm.append(
                    '<input name="payment_method_nonce" type="hidden" value="' +
                      payload.nonce +
                      '" />'
                  );
                  processOrder.removeClass("hidden");
                  checkout_frm.submit();
                } else {
                  checkout_frm.insertBefore(
                    '<div id="validation-msg">Error Validating 3D Secure.  Please try a different payment method.</div>'
                  );
                  $("#loader").hide();
                  processOrder.addClass("hidden");
                  console.log("Liability did not shift", payload);
                  return;
                }
              }
            });
          });
        }
      );
    } else {
      console.log("NNOOOOO  3d sexure");
      // NON 3D SECURE
      braintree.dropin.create(
        {
          authorization: clientToken,
          container: "#payment-form",
        },
        function (createErr, instance) {
          submitButton.addEventListener("click", function () {
            instance.requestPaymentMethod(function (
              requestPaymentMethodErr,
              payload
            ) {
              // Submit payload.nonce to your server
              if (payload.nonce) {
                checkout_frm.hide();
                checkout_frm.append(
                  '<input name="payment_method_nonce" type="hidden" value="' +
                    payload.nonce +
                    '" />'
                );
                checkout_frm.submit();
              }
            });
          });
        }
      );
    }
  },
  populateHalfWidth: function (elem) {
    var elem = $(elem);
    var value = elem.val();
    var half = value / 2;
    elem.next().find(".width_half").html(half);
  },
  removeVariantOptions: function (number) {
    var btn = $('div[remove-variant-number="' + number + '"]');
    btn.unbind().click(function () {
      var selected = $(this);
      var number = selected.attr("remove-variant-number");
      var target = $('div[product-variant-number="' + number + '"]');
      target.slideUp(function () {
        target.remove();
      });
    });
  },
  additionalSize: function () {
    var size_btn = $("#add-another-size-btn");

    size_btn.click(function () {
      var target = $("#product-pricing-grouping");
      var row = target.find(".product-pricing-wrap:last-child").clone();
      var number = row.attr("product-variant-number");
      number++;
      row.attr("product-variant-number", number);
      row.find("input").val("");
      row.removeClass(".triggerTooltip");

      var close_btn = row.find(".btn-remove-variant");
      close_btn.attr("remove-variant-number", number);

      row.hide();
      target.append(row);
      row.slideDown();
      pawLibrary.removeVariantOptions(number);
    });
  },
  addToCart: function (btns) {
    btns.on("click", function (e) {
      e.preventDefault();
      var url = "./ajax/addToCart.inc.php";
      var valid = true;
      var selected = $(this);
      var catId = $("#cat_sel").val();
      var productId = $("#product_sel").val();
      var productPrice = $("#js-price-details-" + catId).attr("value");
      var data = {};
      data["category"] = catId;
      data["product"] = productId;
      data["items"] = [];
      var qty = 0;

      var userData = $("#product-pricing-grouping .product-pricing-wrap");
      $.each(userData, function (key, row) {
        var row = $(row);
        var option =
          row.find('select[name="option_sel"]').length == 1
            ? row.find('select[name="option_sel"]').val()
            : "";
        var depth =
          row.find('input[name="depth_txt"]').length == 1
            ? row.find('input[name="depth_txt"]').val()
            : "";

        var item = {};
        item["height"] = row.find('input[name="height_txt"]').val();
        item["width"] = row.find('input[name="width_txt"]').val();
        item["depth"] = depth;
        item["option"] = option;
        item["qty"] = row.find('input[name="qty_sel"]').val();
        item["number"] = row.attr("product-variant-number");

        if (item["height"] != "" && item["width"] != "") {
          data["items"].push(item);
          qty += parseInt(item["qty"]);
        }
      });

      // IS VALID?
      var inputs = $("#product-pricing-grouping input");
      inputs.removeClass("required");
      $.each(inputs, function (key, row) {
        var row = $(row);
        if (row.val() == "") {
          valid = false;
          row.addClass("required");
        }
        if (
          row.attr("name") == "height_txt" ||
          row.attr("name") == "width_txt"
        ) {
          var max_value = row.attr("data-max-value");
          var entered_value = parseFloat(row.val());
          if (entered_value > max_value) {
            valid = false;
            row.addClass("required");
            row.parent().addClass("triggerTooltip");
          } else {
            row.parent().removeClass("triggerTooltip");
          }
        }
        if (row.attr("name") == "qty_sel") {
          if (row.val() < 1) {
            valid = false;
            row.addClass("required");
          }
        }
      });

      if (valid == true) {
        $.ajax({
          type: "POST",
          cache: false,
          url: url,
          data: data,
          success: function (data) {
            $.fancybox.open(data);
            $.ajax({
              url: "./ajax/minicart.inc.php?init=getminicart",
            }).done(function (response) {
              var minicart = $.parseJSON(response);
              $("#counter-price").html("&pound;" + minicart.price);
              $("#counter")
                .attr("data-total-num", minicart.total)
                .html(minicart.total);
            });
            $("#continue").click(function (e) {
              e.preventDefault();
              $.fancybox.close();
            });
          },
        });
      }
    });

    return false;
  },
  dropdownMenus: function () {
    $(".parent > a").on("click", function (e) {
      var selected = $(this);
      var id = selected.attr("id");
      if (id != "") id = id.replace("showMenu", "");

      if ($("#subMenu" + id).length == 1) {
        e.preventDefault();
      }

      // Show
      if (selected.hasClass("highlight") == true) {
        // Hide
        selected.removeClass("highlight");
        $(".parent-sub").hide();
      } else {
        // Show
        selected.addClass("highlight");
        $(".parent-sub").hide();
        $(".parent a").removeClass("highlight");
        $("#subMenu" + id).fadeIn();
        $("#showMenu" + id).addClass("highlight");
      }
    });
  },
  search: function (term) {
    $("#searchresults-wrap")
      .html('<div id="loading">Searching...</div>')
      .load("ajax/search.inc.php?term=" + term);
  },
  validateForm: function () {
    var errors = 0;

    var name_txt = $("#name_txt");
    var email_txt = $("#email_txt");
    var message_txt = $("#message_txt");
    var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

    if (name_txt.val().length < 3) {
      name_txt.addClass("required_border");
      errors++;
    } else {
      name_txt.removeClass("required_border");
    }

    if (!filter.test(email_txt.val())) {
      email_txt.addClass("required_border");
      errors++;
    } else {
      email_txt.removeClass("required_border");
    }

    if (message_txt.val().length < 3) {
      message_txt.addClass("required_border");
      errors++;
    } else {
      message_txt.removeClass("required_border");
    }

    return errors == 0 ? true : false;
  },

  getTwitterProfilePic: function (elemId, username) {
    $.ajax({
      type: "json",
      url: "../ajax/getTwitter.inc.php?username=" + username,
      beforeSend: function () {
        $("#comment" + elemId + " .img-wrap").html(
          '<img src="./imgs/loader.gif" alt="' +
            username +
            '" class="loader" />'
        );
      },
      success: function (data) {
        var img = data[0].image;
        $("#comment" + elemId + " .img-wrap").html(
          '<img src="' + img + '" alt="' + username + '" class="profile" />'
        );
      },
    });
  },

  generateMap: function (elem, lat, lng, zoomLevel) {
    var myLatlng = new google.maps.LatLng(lat, lng);
    var myOptions = {
      zoom: zoomLevel,
      center: myLatlng,
      scrollwheel: false,
      draggable: true,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
    };

    this.map = new google.maps.Map(document.getElementById(elem), myOptions);
  },

  addMarker: function (lat, lng, location_title, location_add) {
    var myLatlng = new google.maps.LatLng(lat, lng);
    var contentString =
      '<div id="mapContent" class="infoMarker">' +
      "<p><strong>" +
      location_title +
      "</strong></p>" +
      "<p>" +
      location_add +
      "</p>" +
      '<a class="btn" href="http://maps.google.co.uk/maps?daddr=' +
      encodeURI(location_add) +
      '">Get Directions</a>' +
      "</div>";

    var infowindow = new google.maps.InfoWindow({
      content: contentString,
    });

    var marker = new google.maps.Marker({
      position: myLatlng,
      map: this.map,
      title: location_title,
    });

    google.maps.event.addListener(marker, "click", function () {
      infowindow.open(this.map, marker);
    });

    google.maps.event.addDomListener(window, "resize", function () {
      //var center = map.getCenter();
      //map.setCenter(center);
    });
  },
  centerMap: function () {
    var center = this.map.getCenter();
    this.map.setCenter();
  },

  setMap: function (elem, lat, lng, location_title, location_add, drag) {
    var myLatlng = new google.maps.LatLng(lat, lng);
    var myOptions = {
      zoom: 10,
      center: myLatlng,
      scrollwheel: false,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      draggable: drag,
    };

    var map = new google.maps.Map(document.getElementById(elem), myOptions);

    var contentString =
      '<div id="mapContent">' +
      "<p><strong>" +
      location_title +
      "</strong></p>" +
      "<p>" +
      location_add +
      "</p>" +
      '<a class="btn" href="http://maps.google.co.uk/maps?daddr=' +
      encodeURI(location_add) +
      '">Get Directions</a>' +
      "</div>";

    var infowindow = new google.maps.InfoWindow({
      content: contentString,
    });

    var marker = new google.maps.Marker({
      position: myLatlng,
      map: map,
      title: location_title,
      draggable: drag,
    });

    if (drag == true) {
      google.maps.event.addListener(marker, "dragend", function () {
        var point = marker.position;
        if (lat_txt) lat_txt.value = point.$a;
        if (long_txt) long_txt.value = point.ab;
      });
    }

    google.maps.event.addListener(marker, "click", function () {
      infowindow.open(map, marker);
    });

    /*
		google.maps.event.addDomListener(window, 'resize', function() {
			//var center = map.getCenter();
    	//map.setCenter(center);
		});*/
  },

  setMapByGeocode: function (elemId, address, zoomlevel) {
    var mapOptions = {
      zoom: zoomlevel,
      scrollwheel: false,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      draggable: false,
    };

    var contentString =
      '<div id="mapContent">' +
      "<p>" +
      address +
      "</p>" +
      '<a href="http://maps.google.co.uk/maps?daddr=' +
      encodeURI(address) +
      '">Get Directions</a> &raquo;' +
      "</div>";

    var infowindow = new google.maps.InfoWindow({
      content: contentString,
    });

    var geocoder = new google.maps.Geocoder();
    var map = new google.maps.Map(document.getElementById(elemId), mapOptions);

    geocoder.geocode(
      {
        address: address,
      },
      function (results, status) {
        if (status == google.maps.GeocoderStatus.OK) {
          map.setCenter(results[0].geometry.location);
          var marker = new google.maps.Marker({
            map: map,
            position: results[0].geometry.location,
          });

          google.maps.event.addListener(marker, "click", function () {
            infowindow.open(map, marker);
          });
        }
      }
    );
  },

  mobileNav: function () {
    var mobileNavItems = $("#mobile-nav-items");
    var mobileBtn = $("#menu-btn,#close-btn");
    var pageBody = $("#page-wrap-inner");
    var secondMenu = mobileNavItems.find("ul.second");
    if (mobileBtn.length > 0) {
      // Load Menu
      mobileBtn.click(function () {
        if (mobileBtn.hasClass("menu-open") == true) {
          pageBody.removeClass("push");
          mobileBtn.removeClass("menu-open");
        } else {
          pageBody.addClass("push");
          mobileBtn.addClass("menu-open");
        }
      });
    }

    //$('#mobile-nav-inner-items').hcSticky({
    ///	bottom:0
    //});
  },

  dateSelector: function () {
    if ($(".datepicker").length > 0) {
      var d = new Date();
      var today =
        d.getDate() +
        " " +
        this.monthNames[d.getMonth()] +
        " " +
        d.getFullYear().toString().substr(2, 2);
      $(".datepicker")
        .minical({
          trigger: ".datepicker",
          from: new Date(),
          initialize_with_date: false,
        })
        .val("Starting " + today);
    }
  },

  inlineEnquiries: function () {
    var btn = $("#request");

    if (btn.length > 0) {
      btn.fancybox({
        afterLoad: function (current) {
          var selected = $(this);
          var url = window.location.href;
          url = url.split("#");
          url = url[0];

          $("#url_txt").val(url);

          if ($("#product-title").length > 0) {
            var tmp = $("#product-title").html();
            $("#title_txt").val(tmp);
          } else {
            if (window.location.hash.length > 0) {
              var tmp = window.location.hash.substr(1);
              tmp = tmp.replace("enquire-", "");
              if ($("#subTitle" + tmp).length == 1) {
                var subTitle = $("#subTitle" + tmp).html();
                $("#title_txt").val(subTitle);
              }
            }
          }
        },
        onComplete: function (instance, slide) {
          var loading = $("#loader");
          var form = $("#enquiry-frm");

          var name_txt = form.find("#name_txt");
          var email_txt = form.find("#email_txt");
          var msg_txt = form.find("#msg_txt");

          var name_err = form.find("#error-name");
          var email_err = form.find("#error-email");
          var msg_err = form.find("#error-msg");

          $("#send_msg_btn").click(function (e) {
            e.preventDefault();
            var errors = false;

            // validation
            if (name_txt.val() == "") {
              name_txt.addClass("error-input");
              name_err.fadeIn();
              errors = true;
            } else {
              name_txt.removeClass("error-input");
              name_err.hide();
            }
            if (!pawLibrary.isValidEmailAddress(email_txt.val())) {
              email_txt.addClass("error-input");
              email_err.fadeIn();
              errors = true;
            } else {
              email_txt.removeClass("error-input");
              email_err.hide();
            }
            if (msg_txt.val() == "") {
              msg_txt.addClass("error-input");
              msg_err.fadeIn();
              errors = true;
            } else {
              msg_txt.removeClass("error-input");
              msg_err.hide();
            }

            // sent
            if (errors == false) {
              loading.removeClass("hide").fadeIn();
              form.hide();
              var formData = {};
              formData["url_txt"] = form.find("#url_txt").val();
              formData["title_txt"] = form.find("#title_txt").val();
              formData["name_txt"] = form.find("#name_txt").val();
              formData["email_txt"] = form.find("#email_txt").val();
              formData["tel_txt"] = form.find("#tel_txt").val();
              formData["msg_txt"] = form.find("#msg_txt").val();
              formData["items"] = [];

              var userData = $(
                "#product-pricing-grouping .product-pricing-wrap"
              );
              $.each(userData, function (key, row) {
                var row = $(row);

                var option =
                  row.find('select[name="option_sel"]').length == 1
                    ? row.find('select[name="option_sel"]').val()
                    : "";
                var depth =
                  row.find('input[name="depth_txt"]').length == 1
                    ? row.find('input[name="depth_txt"]').val()
                    : "";

                var item = {};
                item["height"] = row.find('input[name="height_txt"]').val();
                item["width"] = row.find('input[name="width_txt"]').val();
                item["depth"] = depth;
                item["option"] = option;
                item["qty"] = row.find('input[name="qty_sel"]').val();

                if (item["height"] != "" && item["width"] != "") {
                  formData["items"].push(item);
                }
              });

              $.ajax({
                url: "./ajax/sendEnquiry.inc.php?init=sendEnquiry",
                data: formData,
                method: "POST",
              }).done(function (response) {
                $("#loader").html("Your message has been sent");
              });
            }
          });
        },
      });
    }
  },

  onResize: function () {},

  banners: function () {
    var banners = $("#banners");

    if (banners.length > 0) {
      banners.slick({
        dots: true,
        infinite: true,
        speed: 500,
        fade: true,
        nextArrow:
          '<span id="rightBannerBtn" class="rightBannerBtn"><i class="fa fa-angle-right" aria-hidden="true"></i></span>',
        prevArrow:
          '<span id="leftBannerBtn" class="leftBannerBtn"><i class="fa fa-angle-left" aria-hidden="true"></i></span>',
        cssEase: "linear",
        appendDots: ".banner-paging .inner-wrap",
        slide: ".item",
      });
    }
  },

  products: function () {},

  setMinorTabs: function () {
    if ($("#minor-tabs").length > 0) {
      var tabs = $("#minor-tabs");
      var btns = tabs.find("li");
      var anchors = tabs.find("a");
      var current = 0;

      btns.unbind().click(function (e) {
        var selected = $(this).attr("id").replace("btn_", "");
        if (current != selected) {
          // selected tab
          btns.removeClass("selected");
          $("#btn_" + selected).addClass("selected");
          // selected textarea
          $("#item_" + current).addClass("hidden");
          $("#item_" + selected)
            .fadeIn()
            .removeClass("hidden")
            .css("display", "inline-block");
          // updated current
          current = selected;
        }
      });
    }
  },

  validateEmail: function (email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  },

  setTabs: function () {
    if ($("#tabs").length > 0) {
      var width = $(window).width();
      var tabs = $("#tabs");
      var btns = tabs.find("li");
      var anchors = tabs.find("a");
      var current = 0;

      if (width > 930) {
        anchors.unbind().click(function (e) {
          e.preventDefault();
        });
        $("#item_" + current).removeClass("hidden");

        btns.unbind().click(function (e) {
          var selected = $(this).attr("id").replace("btn_", "");
          if (current != selected) {
            // selected tab
            btns.removeClass("selected");
            $("#btn_" + selected).addClass("selected");
            // selected textarea
            $("#item_" + current).addClass("hidden");
            $("#item_" + selected)
              .fadeIn()
              .removeClass("hidden")
              .css("display", "inline-block");
            // updated current
            current = selected;
          }
        });
      } else {
        anchors.unbind();
        $(".txt-item-wrap").addClass("hidden").attr("css");
      }
    }
  },

  setGallery: function () {
    $("#slideshow-inner")
      .on("init", function (slick) {
        $("#rightBtn-gallery").trigger("click");
        setTimeout(function () {
          $(".loader").css("opacity", 0).css("zIndex", 0);
        }, 1600);
      })
      .slick({
        centerMode: true,
        nextArrow:
          '<span id="rightBtn-gallery" class="rightBtn-gallery"><img src="./imgs/right-arrow.svg" alt="Move Right" /></span>',
        prevArrow:
          '<span id="leftBtn-gallery" class="leftBtn-gallery"><img src="./imgs/left-arrow.svg" alt="Move Left" /></span>',
        speed: 300,
        centerMode: true,
        slidesToShow: 1,
        variableWidth: true,
      });
  },

  jumpToLinks: function () {
    $("a[href^=#]").click(function (e) {
      var name = $(this).attr("href").substr(1);
      var pos = $("#" + name).offset();
      $("html,body").animate({ scrollTop: pos.top });
      e.preventDefault();
    });
  },

  productBlowUp: function () {
    // Zoom
    $("#gallery .cycle-slide img").unbind();
    $("#gallery .cycle-slide-active img").blowup({
      background: "#fcfcfc",
      width: 138,
      height: 138,
      scale: 1,
    });
  },

  productGallery: function () {
    // Gallery
    if ($("#gallery").length > 0) {
      $("#gallery").on("cycle-next cycle-prev", function (e, opts) {
        $("#thumbs").cycle("goto", opts.currSlide);
      });
      $("#gallery").on("cycle-after", function (event, opts) {
        pawLibrary.productBlowUp();
      });
      pawLibrary.productBlowUp();
    }

    if ($("#thumbs").length > 0) {
      $("#thumbs span.thumb-wrap").click(function () {
        var index = $("#thumbs").data("cycle.API").getSlideIndex(this);
        $("#gallery").cycle("goto", index);
      });
    }
  },
  insertParam: function (key, value, loc) {
    var params = false;

    if (loc == false) {
      var loc = location.href;
      loc += "?cat_sel=&capacity_sel=&type_sel=";
    }

    if (loc.indexOf("?") !== -1) {
      loc = loc.split("?");
      params = loc[1];
      loc = loc[0];
    }
    loc = loc + "?";

    if (params != false) {
      params = params.split("&");
      var x;
      for (x = 0; x < params.length; x++) {
        var tmp = params[x].split("=");
        if (tmp[0] == key) {
          loc += key + "=" + value + "&";
        } else {
          loc += tmp[0] + "=" + tmp[1] + "&";
        }
      }
      loc = loc.slice(0, -1);
    } else {
      loc += key + "=" + value;
    }

    return loc;
  },

  updateProductURL: function (url) {
    window.history.pushState(null, null, url);
  },

  isValidEmailAddress: function (emailAddress) {
    var pattern = /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    return pattern.test(emailAddress);
  },

  getDialCode: function () {
    var getCode = $(".selected-flag .selected-dial-code");
    return getCode.html();
  },

  hideBlocks: function (blocks, offset) {
    blocks.each(function () {
      $(this).offset().top >
        $(window).scrollTop() + $(window).height() * offset &&
        $(this)
          .find(".cd-timeline-ico, .cd-timeline-content")
          .addClass("is-hidden");
    });
  },

  showBlocks: function (blocks, offset) {
    blocks.each(function () {
      $(this).offset().top <=
        $(window).scrollTop() + $(window).height() * offset &&
        $(this).find(".cd-timeline-ico").hasClass("is-hidden") &&
        $(this)
          .find(".cd-timeline-ico, .cd-timeline-content")
          .removeClass("is-hidden")
          .addClass("bounce-in");
    });
  },

  timeline: function () {
    var timelineBlocks = $(".cd-timeline-block");

    //hide timeline blocks which are outside the viewport
    pawLibrary.hideBlocks(timelineBlocks, pawLibrary.offset);

    //on scolling, show/animate timeline blocks when enter the viewport
    $(window).on("scroll", function () {
      !window.requestAnimationFrame
        ? setTimeout(function () {
            pawLibrary.showBlocks(timelineBlocks, pawLibrary.offset);
          }, 100)
        : window.requestAnimationFrame(function () {
            pawLibrary.showBlocks(timelineBlocks, pawLibrary.offset);
          });
    });
  },

  cartesianProductOf: function (arrays) {
    if (!arrays || arrays.length < 1) return [];
    else {
      var header = arrays[0];
      var row = arrays.slice(1);
      var result = [];
      for (var i = 0; i < header.length; i++) {
        var productOfRow = this.cartesianProductOf(row);
        if (productOfRow && productOfRow.length > 0) {
          for (var j = 0; j < productOfRow.length; j++) {
            result.push([header[i]].concat(productOfRow[j]));
          }
        } else result.push([header[i]]);
      }
      return result;
    }
  },

  ajaxPricingFilter: function (productId, options) {
    var dropdowns = $(".filterCats");

    var allVariants = $("#options_sel option");
    var postdata = {};
    var priceId = 0;

    dropdowns.unbind();
    dropdowns.change(function () {
      var add_to_cart_btn = $(".add_to_cart");

      // FILTER DROPDOWNS
      var updated_dropdown_id = $(this).attr("id").replace("variant_sel_", "");
      var updated_dropdown_select = $(this).val();
      var num_of_variants = dropdowns.length;

      // RESTORE DROPDOWNS
      $.each(dropdowns, function (key, row) {
        if (key != updated_dropdown_id) {
          var tmp_selected = $(
            "#variant_sel_wrap" + key + " option:selected"
          ).val();
          $("#variant_sel_wrap" + key).html(
            $(pawLibrary.productDropdowns[key]).html()
          );
          $("#variant_sel_wrap" + key + " select").val(tmp_selected);
        }
      });

      // FILTER INVALID
      var search_options = new Array();
      var x = 0;

      // GET ALL OPTIONS
      $.each(dropdowns, function (key, row) {
        search_options[key] = new Array();
        if (key != updated_dropdown_id) {
          var row_options = $(row).children("option");
          $.each(row_options, function (subId, sub) {
            search_options[key][subId] = sub.value;
          });
        } else {
          search_options[key][0] = $(dropdowns[updated_dropdown_id]).val();
        }
      });

      search_options = pawLibrary.cartesianProductOf(search_options);
      search_options_valid = new Array();

      // REMOVE INVALID
      var x = 0;
      $.each(search_options, function (key, row) {
        var tmp = row.join(" / ");
        var found = false;

        $.each(allVariants, function (varId, varVal) {
          var varVal = $(varVal).text();
          if (varVal == tmp) {
            found = true;
          }
        });

        if (found == true) {
          search_options_valid[x] = tmp;
          x++;
        }
      });

      // PAIR WITH DROPDOWNS
      var allDropdowns = $("#ajaxTarget option");
      var x = 0;
      $.each(dropdowns, function (key, row) {
        if (key != updated_dropdown_id) {
          var row_options = $(row).children("option");
          $.each(row_options, function (tmpVal, tmpOption) {
            var tmp_txt = $(tmpOption).text().replace(" - ", "");
            var tmp_color = tmp_txt;

            // LOOK FOR OPTION
            var found = false;

            $.each(search_options_valid, function (optId, optVal) {
              var optVal = optVal.split(" / ");
              optVal = optVal[key];
              if (optVal == tmp_txt) {
                found = true;
              }
            });

            if (found == false) {
              var tmp_value = $(tmpOption).val();
              $(
                "#ajaxTarget #variant_sel_" +
                  key +
                  " option[value='" +
                  tmp_value +
                  "']"
              ).remove();
            }
            x++;
          });
        }
      });

      // SEARCH PRICE AND THUMB
      var search_for_price = "";
      var search_for_thumb = "";
      $.each(dropdowns, function (key, value) {
        selected = $(value).val();
        search_for_price += selected + " / ";
      });

      if (search_for_price != "") {
        search_for_price = search_for_price.substring(
          0,
          search_for_price.length - 3
        );

        $.each($("#options_sel option"), function (key, row) {
          var row = $(row);
          var tmp_txt = row.text();
          if (tmp_txt == search_for_price) {
            priceId = row.val();
          }
        });

        search_for_thumb = search_for_price;
      }

      // SEARCH FOR PRODUCT DETIALS
      if (priceId > 0) {
        var target = $("#js-price-details-" + priceId);
        options.attr("style", "display:none");
        if (target.length > 0) {
          target.show();
          add_to_cart_btn.show();
        } else {
          target.hide();
          add_to_cart_btn.hide();
        }

        $(".add_to_cart").show();
        $("#cat_sel").val(priceId);
        $("#qty_sel").val(1);
      }

      // HIDE SELECTED
      $.each(dropdowns, function (key, row) {
        var options = $(row).children("option");
        $.each(options, function (optId, opt) {
          var optObj = $(opt);
        });
      });

      pawLibrary.ajaxPricingFilter(productId, options);
    });
  },

  changePrice: function () {
    var dropdown = $("#options_sel");
    var options = $(".price-value");
    var productId = $("#product_sel").val();

    $("#options_sel").hide();

    $("#ajaxTarget").load(
      "./ajax/productOptions.inc.php?init=setProductOptions&id=" + productId,
      function () {
        pawLibrary.productDropdowns = $(".variant-wrap").clone();

        pawLibrary.ajaxPricingFilter(productId, options); // TRIGGER FIRST LOAD

        var setup_dropdown = $(".filterCats");
        var total_dropdowns = setup_dropdown.length;
        if (setup_dropdown.length > 0) {
          $.each(setup_dropdown, function (key, row) {
            if (key > 0) {
              $(row).trigger("change");
            }
          });
        }
      }
    );
  },

  deleteItem: function (e) {
    var response = confirm(
      "Are you sure you want to remove this item from your cart?"
    );
    if (response == false) {
      e.preventDefault();
    }
  },

  sameAsBilling: function () {
    var chk = $("#same_as_billing").is(":checked");
    var frm = $("#del-address-frm");
    var required = $(
      "#del_name_txt,#del_add1_txt,#del_city_txt,#del_country_txt,#del_pcode_txt,#del_phone_txt"
    );
    if (chk == true) {
      frm.hide();
      required.removeAttr("required");
    } else {
      frm.slideDown();
      required.attr("required", "required");
    }
  },

  navigateSubNav: function () {
    var items = $("ul#navigate-sub-nav li");
    var btns = items.find("h3");

    // LOOK UP HEIGHTS
    $.each(items, function (key, row) {
      var row = $(row);
      var inner = row.find(".inner");
      var lookUpHeight = inner.innerHeight();
      inner.attr("data-target-height", lookUpHeight);
      if (row.hasClass("selected") == true) {
        row.addClass("selected");
        inner.css("height", lookUpHeight + "px");
      } else {
        inner.css("height", 0);
      }
    });

    items.click(function () {
      var selected = $(this);
      var target = selected.find(".inner");
      var targetHeight = target.attr("data-target-height");

      items.removeClass("selected");
      selected.addClass("selected");
      items.find(".inner").css("height", 0);
      target.css("height", targetHeight + "px");
    });
  },

  init: function () {
    pawLibrary.mobileNav();
    pawLibrary.inlineEnquiries();
    pawLibrary.banners();
    pawLibrary.jumpToLinks();
    pawLibrary.dropdownMenus();
    pawLibrary.setGallery();

    if ($("#navigate-sub-nav").length > 0) {
      pawLibrary.navigateSubNav();
    }

    var same_as_billing = $("#same_as_billing");
    if (same_as_billing.length > 0) {
      same_as_billing.click(function () {
        pawLibrary.sameAsBilling();
      });
    }

    var remove_from_cart_btn = $(".remove_from_cart_btn");
    if (remove_from_cart_btn.length > 0) {
      remove_from_cart_btn.click(function (e) {
        pawLibrary.deleteItem(e);
      });
    }
    var delivery_dropdown = $("#delivery_sel");
    if (delivery_dropdown.length > 0) {
      pawLibrary.deliveryFrm();
      var delivery_dropdown = $(".delivery_sel option");
      if (delivery_dropdown.length > 1) {
        pawLibrary.deliverySetup(delivery_dropdown);
      }
    }

    var cart_btns = $(".add_to_cart");
    if (cart_btns.length > 0) {
      this.changePrice();
      this.additionalSize();
      this.addToCart(cart_btns);
    }

    if ($("#tabs").length > 0) {
      var tabs = $("#tabs-btn span");
      var tabsContent = $(".tab-content-inner");
      tabs.click(function () {
        var selected = $(this).attr("data-tab-btn");
        tabs.removeClass("selected");
        $('*[data-tab-btn="' + selected + '"]').addClass("selected");
        //
        tabsContent.hide().addClass("hidden");
        $("#tab" + selected + "-txt")
          .removeClass("hidden")
          .fadeIn();
      });

      var topLevel = $("a.toggle");
      topLevel.click(function (e) {
        e.preventDefault();
        var $this = $(this);
        topLevel.removeClass("active");
        if ($this.next().hasClass("show")) {
          $this.next().removeClass("show");
          $this.next().slideUp(350);
        } else {
          $this.parent().parent().find("li .inner").removeClass("show");
          $this.parent().parent().find("li .inner").slideUp(350);
          $this.next().toggleClass("show");
          $this.next().slideToggle(350);
          $($this[0]).addClass("active");
        }
      });
    }

    if ($("#gallery").length > 0) {
      pawLibrary.productGallery();
    }

    var fancyBox = $(".fancybox");
    if (fancyBox.length > 0) {
      fancyBox.fancybox({
        padding: 0,
      });
    }

    var base = $("base").attr("href");
    $("#mobile-sub-menu").on("change", function () {
      var value = $(this).val().replace("./", "");
      window.location = base + value;
    });
  },
};

$(window).load(function () {
  pawLibrary.init();
});

$(window).ready(function () {});
